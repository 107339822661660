<div class="scroll-container full-height white-scrollbar">
  <div class="background-element position-absolute"></div>

  <div class="fxcolumn fxstartcenter">
    <div class="welcome-element fxstart fxrow">
      <app-logo />
      <div>
        <div class="hi">{{ 'NoOrganization.Hi' | translate }}</div>
        <div class="welcome-back">
          {{ 'NoOrganization.Welcome Back' | translate }}
        </div>
      </div>
    </div>

    <div class="no-organization fxcolumn">
      <h1>
        {{ 'NoOrganization.Header' | translate }}
      </h1>
      <span class="information-text">
        {{ 'NoOrganization.Content' | translate }}
      </span>
    </div>
  </div>
</div>
