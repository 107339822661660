<div class="dialog-wrapper" data-cy="dialog-wrapper">
  <mat-icon
    class="close-icon"
    svgIcon="close-small"
    data-cy="close-icon-button"
    (click)="closeDialog()"
  />

  <h1 mat-dialog-title data-cy="dialog-title">
    <ng-content select="[title]" />
  </h1>

  <div class="scrollable-content">
    <div class="dialog-subheader" data-cy="dialog-subheader-content">
      <ng-content select="[subheader]" />
    </div>

    <div mat-dialog-content data-cy="dialog-content">
      <ng-content select="[content]" />
    </div>

    <div mat-dialog-actions>
      <span class="dialog-footer-text">
        <ng-content select="[footer]" />
      </span>
      <div class="fxflex fxcenterend">
        <ng-content select="[actions]" />
      </div>
    </div>
  </div>
</div>
