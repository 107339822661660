<div class="output-characters fxflex fxcentercenter">
  <span class="fxrow fxstartcenter margin-r-10" data-cy="character-count">
    @if (charactersCount && charactersCount !== 0) {
      {{ charactersCount }}
      {{
        (charactersCount === 1 ? 'Rewrite.Character' : 'Rewrite.Characters')
          | translate
      }}
    }
    @if (showSpinner) {
      <mat-spinner
        class="margin-l-5"
        data-cy="stream-spinner"
        diameter="20"
      ></mat-spinner>
    } @else if (text) {
      - {{ text | translate: { textLength } }}
    }
  </span>
</div>
