import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Prompt, PromptsState } from './models';
import { promptsAdapter, promptsFeature } from './prompts.reducer';

const promptsFeatureSelector = createFeatureSelector<PromptsState>(
  promptsFeature.name,
);

const { selectAll, selectEntities, selectTotal } =
  promptsAdapter.getSelectors();

export const selectAllPrompts = createSelector(
  promptsFeatureSelector,
  selectAll,
);

export const selectPromptsEntities = createSelector(
  promptsFeatureSelector,
  selectEntities,
);

export const selectPromptsLength = createSelector(
  promptsFeatureSelector,
  selectTotal,
);

export const selectActivePrompts = createSelector(
  selectAllPrompts,
  (prompts): Prompt[] => prompts.filter(prompt => prompt.activatedVersion),
);

export const selectActivePromptsIds = createSelector(
  selectActivePrompts,
  (prompts): string[] => prompts.map(prompt => prompt.id),
);

export const selectPromptById = (promptId: string) =>
  createSelector(selectPromptsEntities, entities => {
    return entities[promptId];
  });

export const selectIsPromptDefaultById = (promptId: string) =>
  createSelector(selectPromptById(promptId), prompt => prompt?.isDefault);
