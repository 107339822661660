import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { userActions, userFeature } from 'rio-core';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { promptChainsActions } from './prompt-chains.actions';
import { PromptChainsService } from './prompt-chains.service';

@Injectable()
export class PromptChainsEffects {
  loadChains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(promptChainsActions.loadChains),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([, organizationId]) =>
        this.promptChainsService.getPromptChains(organizationId).pipe(
          map(chains => promptChainsActions.loadChainsSuccess({ chains })),
          catchError(() => of(promptChainsActions.loadChainsFailure())),
        ),
      ),
    ),
  );

  selectOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.selectOrganization),
      map(() => this.router.url),
      filter(url => url === '/' || url.includes('/admin-panel/prompt-chains')),
      map(() => promptChainsActions.loadChains()),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private promptChainsService: PromptChainsService,
    private router: Router,
  ) {}
}
