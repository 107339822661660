<mat-form-field
  appearance="outline"
  class="border white-background radius-6 custom-arrow outline-dropdown no-padding full-width"
  [ngClass]="dropdownPosition"
  [attr.data-cy]="dataCy"
  (click)="onOpenDropdown()"
>
  <mat-select
    #selectPanel
    data-cy="select"
    panelClass="single-select-panel outline-dropdown-panel scrollable"
    [formControl]="formControl"
    [placeholder]="selectedOption ? selectedOption : (placeholder | translate)"
    (closed)="onCloseDropdown()"
    (selectionChange)="onSelectionChange($event)"
  >
    @for (option of options; track $index) {
      <mat-option data-cy="option" [value]="option.id">
        <span class="black-text">{{ option.name | translate }}</span>
      </mat-option>
    }
  </mat-select>
  <mat-icon class="dropdown-icon" matSuffix svgIcon="chevron-up" />
</mat-form-field>
