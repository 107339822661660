import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RioPage } from 'rio-models';

@Component({
  selector: 'app-header-navigation',
  imports: [TranslateModule],
  templateUrl: './header-navigation.component.html',
  styleUrl: './header-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationComponent {
  @Input({ required: true }) routes: RioPage[] = [];
  @Output() navigate = new EventEmitter<RioPage>();
}
