import { createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { categoriesActions } from './categories.actions';
import { CategoriesState, PromptCategory } from './models';

export const categoriesAdapter = createEntityAdapter<PromptCategory>();

export const categoriesInitialState: CategoriesState =
  categoriesAdapter.getInitialState();

export const categoriesReducer = createReducer(
  categoriesInitialState,

  on(categoriesActions.loadCategoriesSuccess, (state, { categories }) =>
    categoriesAdapter.setAll(categories, state),
  ),

  on(categoriesActions.clearAllCategories, state =>
    categoriesAdapter.removeAll(state),
  ),

  on(categoriesActions.createCategorySuccess, (state, { category }) =>
    categoriesAdapter.addOne(category, state),
  ),

  on(
    categoriesActions.editCategorySuccess,
    (state, { category }) =>
      categoriesAdapter.updateOne(
        { id: category.id, changes: category },
        state,
      ), //TODO
  ),

  on(categoriesActions.deleteCategorySuccess, (state, { categoryId }) =>
    categoriesAdapter.removeOne(categoryId, state),
  ),
);

export const categoriesFeature = createFeature({
  name: 'categories',
  reducer: categoriesReducer,
});
