import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Prompt } from '../+state/models';

@Pipe({
  name: 'promptName',
})
export class PromptNamePipe implements PipeTransform {
  transform(prompt: string, allPrompts: Dictionary<Prompt>): string {
    return allPrompts[prompt]?.name;
  }
}
