import { createFeature, createReducer, on } from '@ngrx/store';
import { SystemState } from './models';
import { systemActions } from './system.actions';

export const systemInitialState: SystemState = {
  versions: [],
};

export const systemReducer = createReducer(
  systemInitialState,

  on(systemActions.getSystemVersionsSuccess, (state, { versions }) => ({
    ...state,
    versions,
  })),
);

export const systemFeature = createFeature({
  name: 'system',
  reducer: systemReducer,
});
