import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ChipType, IconPosition } from 'rio-models';
import { DisableTooltipDirective } from 'ui-core';
@Component({
  selector: 'app-chip',
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    DisableTooltipDirective,
    TranslateModule,
  ],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input({ required: true }) text: string;
  @Input() type: ChipType = 'primary';
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() iconPosition: IconPosition = 'left';
  @Input() clickable = true;
  @Input() large = false;
  @Input() disabled = false;
  @Input() maxWidth: number;
  @Input() truncate: boolean;
}
