import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  inject,
} from '@angular/core';
import { SingleToast, StackedToast } from '../../models/models';
import { SingleToastService } from '../../services/single-toast.service';
import { StackedToastService } from '../../services/stacked-toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html',
  styleUrls: ['./toasts-container.component.scss'],
  imports: [ToastComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastsContainerComponent {
  singleToastService = inject(SingleToastService);
  stackedToastService = inject(StackedToastService);

  toast: Signal<SingleToast> = this.singleToastService.singleToast;
  toasts: Signal<StackedToast[]> = this.stackedToastService.stackedToasts;

  closeStackedToast(toast: StackedToast) {
    this.stackedToastService.closeToast(toast);
  }

  closeSingleToast() {
    this.singleToastService.closeToast();
  }
}
