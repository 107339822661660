import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  DialogWrapperComponent,
  WhiteSpacesValidator,
} from 'rio-ui';

@Component({
  selector: 'app-add-organization-dialog',
  imports: [
    DialogWrapperComponent,
    ButtonComponent,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
  ],
  templateUrl: './add-organization-dialog.component.html',
  styleUrl: './add-organization-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrganizationDialogComponent {
  dialogRef = inject(MatDialogRef);

  form = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      WhiteSpacesValidator,
    ]),
    isInternal: new FormControl(false),
  });

  confirm(): void {
    const { name, isInternal } = this.form.value;
    this.dialogRef.close({ name, isInternal });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
