import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from 'rio-models';

@Component({
  selector: 'app-table-select',
  templateUrl: './table-select.component.html',
  styleUrl: './table-select.component.scss',
  imports: [
    NgClass,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TableSelectComponent,
      multi: true,
    },
  ],
})
export class TableSelectComponent implements OnInit, ControlValueAccessor {
  cdr = inject(ChangeDetectorRef);

  @ViewChild('selectPanel') selectPanel: MatSelect;

  @Input({ required: true }) options: SelectOption[];
  @Input({ required: false }) placeholder: string;
  @Input({ required: false }) selectedOption = '';
  @Input({ required: false }) dataCy: string;
  @Input()
  set disabled(value: boolean) {
    if (value) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  @Output() selectionChange = new EventEmitter<string>();
  @Output() openDropdown = new EventEmitter<boolean>();

  formControl = new FormControl();
  dropdownPosition: string;

  //  control value access
  onTouched: () => void;
  onChanged: (value: string) => void;

  ngOnInit() {
    if (this.selectedOption) {
      this.formControl.setValue(this.selectedOption);
    }
  }

  writeValue(option: string) {
    this.selectedOption = option;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onCloseDropdown() {
    this.selectPanel._onBlur();
    this.dropdownPosition = null;
    this.openDropdown.emit(false);
  }

  onOpenDropdown() {
    const overlayClass =
      this.selectPanel.panel?.nativeElement.offsetParent.classList;
    const positionAbove = overlayClass?.contains('mat-mdc-select-panel-above');

    this.dropdownPosition = positionAbove ? 'panel-above' : 'panel-below';
    this.openDropdown.emit(true);
  }

  onSelectionChange(event: MatSelectChange) {
    this.selectionChange.emit(event.value);
  }
}
