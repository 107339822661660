import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { RioPage } from 'rio-models';

export const routerActions = createActionGroup({
  source: 'Router',
  events: {
    'Navigate To Prompt': props<{ id: string; chainName?: string }>(),
    'Navigate To Prompts Table': emptyProps(),
    'Header Navigation': props<{ page: RioPage }>(),

    initialNavigationToDefaultRoute: emptyProps(),
    navigateToDefaultRoute: emptyProps(),

    'Navigate to No Organization Page': emptyProps(),
    NavigateToAPISettings: emptyProps(),

    navigateToPromptChain: props<{ id: string; promptName?: string }>(),
    navigateToPromptChainsTable: emptyProps(),
  },
});
