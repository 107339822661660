import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PromptChain } from 'rio-models';
import { PromptChainsState } from './models';
import {
  promptChainsAdapter,
  promptChainsFeature,
} from './prompt-chains.reducer';

const promptChainsFeatureSelector = createFeatureSelector<PromptChainsState>(
  promptChainsFeature.name,
);

const { selectAll, selectEntities } = promptChainsAdapter.getSelectors();

export const selectAllPromptChains = createSelector(
  promptChainsFeatureSelector,
  selectAll,
);

export const selectActivePromptsChains = createSelector(
  selectAllPromptChains,
  (chains): PromptChain[] => chains.filter(chain => chain.isActive),
);

export const selectChainsEntities = createSelector(
  promptChainsFeatureSelector,
  selectEntities,
);

export const selectChainById = (chainId: string) =>
  createSelector(selectChainsEntities, entities => {
    return entities[chainId];
  });
