import { createFeature, createReducer, on } from '@ngrx/store';
import { UserState } from './models';
import { userActions } from './user.actions';

export const userInitialState: UserState = {
  details: {
    id: '',
    email: '',
    username: '',
    firstName: '',
    lastName: '',
  },
  uiLanguage: 'de',
  keycloakRole: null,
  memberships: [],
  currentOrganizationId: '',
};

export const userReducer = createReducer(
  userInitialState,

  on(userActions.loadUser, (state, { user }) => ({
    ...state,
    details: user,
  })),

  on(
    userActions.changeLanguageSuccess,
    userActions.loadLanguageSuccess,
    (state, { uiLanguage }) => ({
      ...state,
      uiLanguage,
    }),
  ),

  on(userActions.setMemberships, (state, { memberships }) => ({
    ...state,
    memberships,
    currentOrganizationId: memberships[0]?.organizationId ?? '',
  })),

  on(userActions.selectOrganization, (state, { organizationId }) => ({
    ...state,
    currentOrganizationId: organizationId,
  })),

  on(userActions.setKeycloakRole, (state, { keycloakRole }) => ({
    ...state,
    keycloakRole,
  })),
);

export const userFeature = createFeature({
  name: 'user',
  reducer: userReducer,
});
