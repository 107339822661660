import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { localStorageActions } from 'local-storage';
import moment from 'moment';
import 'moment/locale/de'; // without this import German translation wouldn't work
import { TrackingService } from 'rio-tracking';
import { of, switchMap } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { selectOrganizationIsInternal } from '../organizations/organizations.selectors';
import { UiLanguage } from './models';
import { userActions } from './user.actions';
import { selectUserLanguageToChangeTo } from './user.selectors';

@Injectable()
export class UserEffects {
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.logout),
      map(() => {
        this.trackingService.resetUser();
        this.keycloakService.logout();

        return userActions.logoutSuccess();
      }),
      catchError(() => of(userActions.logoutFailure())),
    ),
  );

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.changeLanguage),
      concatLatestFrom(() => this.store.select(selectUserLanguageToChangeTo)),
      switchMap(([, newLanguage]) =>
        this.translateService.use(newLanguage).pipe(
          tap(() => {
            moment.locale(newLanguage);
          }),
          mergeMap(() => [
            userActions.changeLanguageSuccess({ uiLanguage: newLanguage }),
            localStorageActions.setLocalStorageItem({
              key: 'language',
              value: newLanguage,
            }),
          ]),
          catchError(() => of(userActions.changeLanguageFailure())),
        ),
      ),
    ),
  );

  loadLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadLanguage),
      map(() => {
        const localStorageLanguage = localStorage.getItem('language');

        // not using the value directly to make sure we only use the supported values
        // otherwise default to 'de'
        const newLanguage: UiLanguage =
          localStorageLanguage === 'en' ? 'en' : 'de';
        moment.locale(newLanguage);
        this.translateService.use(newLanguage);

        return userActions.loadLanguageSuccess({ uiLanguage: newLanguage });
      }),
      catchError(() => {
        this.translateService.use('de');
        moment.locale('de');

        return of(userActions.loadLanguageFailure());
      }),
    ),
  );

  selectOrganization$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.selectOrganization),
        concatLatestFrom(() => this.store.select(selectOrganizationIsInternal)),
        tap(([{ organizationId }, isInternal]) => {
          this.trackingService.setOrganization(organizationId, isInternal);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private keycloakService: KeycloakService,
    private translateService: TranslateService,
    private trackingService: TrackingService,
  ) {}
}
