import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  organizationsActions,
  routerActions,
  selectIsUserSuperAdminOrSupport,
  selectUserLanguageToChangeTo,
  selectUserOrganizations,
  selectUserRole,
  selectUserRoutes,
  systemActions,
  systemFeature,
  userActions,
  userFeature,
  userTrackingActions,
} from 'rio-core';
import {
  complexDialogConfig,
  RioPage,
  simpleDialogConfig,
  SystemVersionEntry,
} from 'rio-models';
import { IconButtonComponent, MatIconRegistryModule } from 'rio-ui';
import { combineLatest, filter, mergeMap, take, tap } from 'rxjs';
import { AddOrganizationDialogComponent } from '../add-organization-dialog/add-organization-dialog.component';
import { EditUserDialogComponent } from '../edit-user-dialog/edit-user-dialog.component';
import { HeaderNavigationComponent } from '../header-navigation/header-navigation.component';
import { SelectOrganizationComponent } from '../select-organization/select-organization.component';
import { SystemVersionsDialogComponent } from '../system-versions-dialog/system-versions-dialog.component';

@Component({
  selector: 'app-header',
  imports: [
    TranslateModule,
    HeaderNavigationComponent,
    AsyncPipe,
    UpperCasePipe,
    SelectOrganizationComponent,
    MatIconRegistryModule,
    IconButtonComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  store = inject(Store);
  matDialog = inject(MatDialog);
  destroyRef = inject(DestroyRef);

  userRoutes$ = this.store.select(selectUserRoutes);
  userOrganizations$ = this.store.select(selectUserOrganizations);
  selectedOrganizationId$ = this.store.select(
    userFeature.selectCurrentOrganizationId,
  );
  unselectedLanguage$ = this.store.select(selectUserLanguageToChangeTo);
  isSuperAdminOrSupport$ = this.store.select(selectIsUserSuperAdminOrSupport);

  userDetails$ = this.store.select(userFeature.selectDetails);
  versions$ = this.store.select(systemFeature.selectVersions);
  userRole$ = this.store.select(selectUserRole);

  dialogRef: MatDialogRef<EditUserDialogComponent>;
  dialogOrganizationRef: MatDialogRef<AddOrganizationDialogComponent>;

  ngOnInit() {
    this.versions$
      .pipe(
        filter(versions => versions.length > 0),
        tap(versions => this.openVersionsDialog(versions)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  logoutUser() {
    this.store.dispatch(userActions.logout());
  }

  changeLanguage() {
    this.store.dispatch(userActions.changeLanguage());
  }

  onOrganizationSelected(organizationId: string) {
    this.store.dispatch(userActions.selectOrganization({ organizationId }));
  }

  editProfile() {
    combineLatest([this.userDetails$, this.userRole$])
      .pipe(
        take(1),
        tap(() =>
          this.store.dispatch(userTrackingActions.openUserProfileDialog()),
        ),
        mergeMap(([userDetails, userRole]) => {
          const dialogConfig: MatDialogConfig = {
            ...complexDialogConfig,
            data: { details: userDetails, role: userRole },
          };

          this.dialogRef = this.matDialog.open(
            EditUserDialogComponent,
            dialogConfig,
          );

          return this.dialogRef.afterClosed();
        }),
      )
      .subscribe();
  }

  onNavigate(page: RioPage) {
    this.store.dispatch(routerActions.headerNavigation({ page }));
  }

  onAddOrganization() {
    this.dialogOrganizationRef = this.matDialog.open(
      AddOrganizationDialogComponent,
      complexDialogConfig,
    );

    this.dialogOrganizationRef
      .afterClosed()
      .pipe(
        take(1),
        filter(result => result),
        tap(({ name, isInternal }) => {
          this.store.dispatch(
            organizationsActions.createOrganization({ name, isInternal }),
          );
          this.dialogOrganizationRef = null;
        }),
      )
      .subscribe();
  }

  logoClicked(event: MouseEvent) {
    const openDialog = Boolean(event.shiftKey);
    this.store.dispatch(systemActions.headerLogoClicked({ openDialog }));
  }

  private openVersionsDialog(versions: SystemVersionEntry[]) {
    const dialogConfig: MatDialogConfig<SystemVersionEntry[]> = {
      ...simpleDialogConfig,
      data: versions,
    };

    const dialog = this.matDialog.open(
      SystemVersionsDialogComponent,
      dialogConfig,
    );

    return dialog.afterClosed();
  }
}
