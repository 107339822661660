import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-loading-dots',
  templateUrl: './loading-dots.component.html',
  styleUrls: ['./loading-dots.component.scss'],
  imports: [CommonModule, TranslateModule],
})
export class LoadingDotsComponent {
  @Input({ required: true }) loaded: number;
  @Input({ required: true }) total: number;
  @Input({ required: true }) text: string;
}
