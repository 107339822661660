import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { PromptCategory } from '../+state/models';

@Pipe({
  name: 'categoryName',
})
export class CategoryNamePipe implements PipeTransform {
  transform(
    category: string,
    allCategories: Dictionary<PromptCategory>,
  ): string {
    return allCategories[category]?.name;
  }
}
