import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from 'rio-models';

@Component({
  selector: 'app-multi-select',
  imports: [MatSelectModule, TranslateModule, MatIconModule, MatTooltipModule],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectComponent,
      multi: true,
    },
  ],
})
export class MultiSelectComponent implements OnChanges, ControlValueAccessor {
  cdr = inject(ChangeDetectorRef);

  @ViewChild('select') select: MatSelect;

  @Input({ required: true }) options: SelectOption[];
  @Input({ required: false }) dataCy: string;
  @Input({ required: false }) label: string;
  @Input({ required: false }) placeholder: string;
  @Input({ required: false }) selectedPlaceholder: string;
  @Input({ required: false }) showAll = false;

  availableOptions: SelectOption[];
  selectedOptions: string[] = [];
  allOption: SelectOption = { id: 'all', name: 'Show all' };
  disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    const { showAll } = changes;
    const showAllOption = showAll?.currentValue;

    this.availableOptions = showAllOption
      ? [this.allOption, ...this.options]
      : this.options;
  }

  onTouched: () => void;
  onChanged: (value: string[]) => void;

  writeValue(value: string[]): void {
    this.selectedOptions = value;
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  selectOption(option: string) {
    const selectedOptions = this.getSelectedOptions(option);
    this.writeValue(selectedOptions);
  }

  openDropdown(isOpen: boolean) {
    if (isOpen) {
      return;
    }

    this.onChanged(this.selectedOptions);
    this.onTouched();
  }

  private getSelectedOptions(option: string): string[] {
    const selected = this.select.value || [];
    const noneSelected = selected.length === 0;
    const allSelected = selected.length === this.options.length;

    if (this.showAll) {
      if (option === this.allOption.id || noneSelected || allSelected) {
        return [this.allOption.id];
      } else {
        return selected.filter(item => item !== this.allOption.id);
      }
    } else {
      return selected;
    }
  }
}
