export type ChipType =
  | 'primary'
  | 'light-primary'
  | 'black'
  | 'outlined'
  | 'violet'
  | 'light-violet'
  | 'dark-violet';

export type IconPosition = 'left' | 'right' | '';

export interface ChipIcon {
  icon: string;
  position: IconPosition;
}

export const chainIcon: ChipIcon = {
  icon: 'prompt-chain',
  position: 'left',
};

export const defaultPromptIcon: ChipIcon = {
  icon: 'rtr-icon',
  position: 'right',
};

export const uncategorizedIcon: ChipIcon = {
  icon: 'dots',
  position: 'left',
};

export const noIcon: ChipIcon = {
  icon: '',
  position: '',
};
