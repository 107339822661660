import {} from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileUploadModule, FileUploader } from 'ng2-file-upload';
import { MatIconRegistryModule } from '../../../icon-registry.module';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    FileUploadModule,
    MatIconRegistryModule,
  ],
})
export class UploadButtonComponent {
  @ViewChild('uploadElement') uploadElement: ElementRef;

  @Output() setShowCategories = new EventEmitter<void>();
  @Output() fileSizeExceeded = new EventEmitter<void>();

  accept = '.txt, .docx, .pdf, .html';

  _fileUploader: FileUploader;

  @Input() icon = 'upload';
  @Input()
  set fileUploader(uploader: FileUploader) {
    // clearing name is needed to be able to upload the same file again
    if (uploader) {
      uploader.onAfterAddingFile = () => {
        this.uploadElement.nativeElement.value = '';
      };
      uploader.onWhenAddingFileFailed = (item, filter) => {
        switch (filter.name) {
          case 'fileSize':
            this.uploadElement.nativeElement.value = '';
            this.fileSizeExceeded.emit();
            break;
          default:
            console.error('Unknown error occurred.');
            break;
        }
      };
    }
    this._fileUploader = uploader;
  }

  get fileUploader(): FileUploader {
    return this._fileUploader;
  }

  onBlur(event: FocusEvent): void {
    const source = event['sourceCapabilities'];
    if (source && !source.firesTouchEvents) {
      this.setShowCategories.emit();
    }
  }
}
