import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  signal,
} from '@angular/core';
import { IconButtonComponent } from '../buttons/icon-button/icon-button.component';

@Component({
  selector: 'app-thread-selector',
  templateUrl: './thread-selector.component.html',
  styleUrls: ['./thread-selector.component.scss'],
  imports: [IconButtonComponent],
})
export class ThreadSelectorComponent implements OnInit {
  @Input({ required: true }) threadsIds: string[] = [];
  @Input({ required: true }) set selectedId(value: string) {
    const items = this.threadsIds.map((elementId: string) => ({
      id: elementId,
      active: elementId === value,
    }));

    this.threads.set(items);
  }
  @Input({ required: true }) disabled = false;

  @Output() threadSelected = new EventEmitter<string>();
  @Output() previousThreadOpened = new EventEmitter();
  @Output() nextThreadOpened = new EventEmitter();
  @Output() specificThreadOpened = new EventEmitter();
  @Output() threadSelectorShown = new EventEmitter<string>();

  threads = signal<ThreadItem[]>([]);

  upDisabled = computed(() => this.findActiveIndex() === 0);
  downDisabled = computed(
    () => this.findActiveIndex() === this.threads().length - 1,
  );

  ngOnInit() {
    this.threadSelectorShown.emit('tutorial-thread-selector');
  }

  onDotUsed(index: number) {
    if (this.disabled) {
      return;
    }

    const current = this.findActiveIndex();
    if (index === current) {
      return;
    }

    this.selectThread(index);
    this.specificThreadOpened.emit();
  }

  navigateUp() {
    if (this.upDisabled()) {
      return;
    }

    const index = this.findActiveIndex();
    this.selectThread(index - 1);
    this.previousThreadOpened.emit();
  }

  navigateDown() {
    if (this.downDisabled()) {
      return;
    }

    const index = this.findActiveIndex();
    this.selectThread(index + 1);
    this.nextThreadOpened.emit();
  }

  private selectThread(index: number) {
    if (this.disabled) {
      return;
    }

    this.threads.update(items => {
      items.forEach((item, i) => (item.active = i === index));

      return [...items];
    });

    this.threadSelected.emit(this.threads()[index].id);
  }

  private findActiveIndex(): number {
    return this.threads().findIndex(item => item.active);
  }
}

export interface ThreadItem {
  id: string;
  active: boolean;
}
