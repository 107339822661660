import { createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { Prompt, PromptsState } from './models';
import { promptsActions } from './prompts.actions';

export const promptsAdapter = createEntityAdapter<Prompt>();

export const promptsInitialState: PromptsState =
  promptsAdapter.getInitialState();

export const promptsReducer = createReducer(
  promptsInitialState,

  on(promptsActions.loadPromptsSuccess, (state, { prompts }) =>
    promptsAdapter.setAll(prompts, state),
  ),

  on(promptsActions.deletePromptSuccess, (state, { promptId }) =>
    promptsAdapter.removeOne(promptId, state),
  ),

  on(promptsActions.clearAllPrompts, state => promptsAdapter.removeAll(state)),
);

export const promptsFeature = createFeature({
  name: 'prompts',
  reducer: promptsReducer,
});
