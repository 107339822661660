import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from 'rio-models';

@Pipe({
  name: 'valueToName',
})
export class ValueToNamePipe implements PipeTransform {
  transform(value: string, roles: SelectOption[]): string {
    return roles.find(role => role.id === value)?.name;
  }
}
