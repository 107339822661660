import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_PREFIX, RESOURCES } from 'rio-core';
import { PromptChain, PromptChainResponse } from 'rio-models';
import { map, Observable } from 'rxjs';
import { PromptChainsResponse } from './models';

@Injectable()
export class PromptChainsService {
  API_PREFIX = inject(API_PREFIX);
  httpClient = inject(HttpClient);

  getPromptChains(organizationId: string): Observable<PromptChain[]> {
    const url = `${this.API_PREFIX}${RESOURCES.CHAINS.chains(organizationId)}`;

    return this.httpClient
      .get<PromptChainsResponse>(url)
      .pipe(map(response => response.chains.map(this.chainAdapter)));
  }

  private chainAdapter(chain: PromptChainResponse): PromptChain {
    return {
      type: 'chain',
      id: chain.id,
      name: chain.name,
      categories: chain.categories,
      isActive: chain.is_active,
      prompts: chain.prompts,
    };
  }
}
