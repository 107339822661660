import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { userActions } from 'rio-core';
import { HeaderComponent } from 'rio-header';
import { ToastsContainerComponent } from 'rio-toasts';
import { cypressTranslationLoaded } from './cypress-helper';
@Component({
  imports: [
    RouterModule,
    TranslateModule,
    HeaderComponent,
    ToastsContainerComponent,
  ],
  providers: [TranslateService],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  store = inject(Store);
  title = 'tio-rewrite';

  constructor() {
    cypressTranslationLoaded();
  }

  ngOnInit(): void {
    this.store.dispatch({ type: '[App] Init' });
    this.store.dispatch(userActions.loadLanguage());
  }
}
