import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { adminSettingsPage, getDefaultRoute } from '../../sources/rio-pages';
import { selectUserRole } from '../user/user.selectors';
import { routerActions } from './router.actions';

@Injectable()
export class RouterEffects {
  navigateToPrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPrompt),
        tap(({ id, chainName }) => {
          this.router.navigate(['/admin-panel/prompts', id], {
            state: { chainName },
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToPromptsTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPromptsTable),
        tap(() => {
          this.router.navigate(['/admin-panel/prompts']);
        }),
      ),
    { dispatch: false },
  );

  navigateToAPISettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToAPISettings),
        tap(() => {
          this.router.navigate([adminSettingsPage.route]);
        }),
      ),
    { dispatch: false },
  );

  headerNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.headerNavigation),
        tap(({ page }) => {
          this.router.navigate([page.route]);
        }),
      ),
    { dispatch: false },
  );

  initialNavigationToDefaultRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerActions.initialNavigationToDefaultRoute),
      filter(() => this.location.path() === ''),
      map(() => routerActions.navigateToDefaultRoute()),
    ),
  );

  navigateToDefaultRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToDefaultRoute),
        concatLatestFrom(() => [this.store.select(selectUserRole)]),
        map(([, role]) => getDefaultRoute(role)),
        tap(route => {
          this.router.navigate([route]);
        }),
      ),
    { dispatch: false },
  );

  navigateToNoOrganizationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToNoOrganizationPage),
        tap(() => {
          this.router.navigate(['no-organization']);
        }),
      ),
    { dispatch: false },
  );

  navigateToPromptChain$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPromptChain),
        tap(({ id, promptName }) => {
          this.router.navigate(['/admin-panel/prompt-chains', id], {
            state: { promptName },
            queryParams: { tab: 'create-chain' },
          });
        }),
      ),
    { dispatch: false },
  );

  navigateToPromptChainsTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerActions.navigateToPromptChainsTable),
        tap(() => this.router.navigate(['/admin-panel/prompt-chains'])),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private location: Location,
  ) {}
}
