import { isEmpty, values } from 'lodash';

export function isValidJson(json: string) {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
}

export function checkObjectNotEmpty<T>(obj: T): boolean {
  const empty = values(obj).some(value => value === undefined) || isEmpty(obj);

  return !empty;
}
