import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  imports: [MatDialogModule, TranslateModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapperComponent {
  dialogRef = inject(MatDialog);

  closeDialog() {
    this.dialogRef.closeAll();
  }
}
