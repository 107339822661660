import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PromptChainsEffects } from './prompt-chains.effects';
import { promptChainsFeature } from './prompt-chains.reducer';
import { PromptChainsService } from './prompt-chains.service';

@NgModule({
  imports: [
    StoreModule.forFeature(promptChainsFeature),
    EffectsModule.forFeature([PromptChainsEffects]),
  ],
  providers: [PromptChainsService],
})
export class PromptChainsStoreModule {}
