import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { FileUploader, Headers } from 'ng2-file-upload';
import { Observable, map } from 'rxjs';
import { API_PREFIX } from '../utils/injection-tokens';
import { RESOURCES } from './resources';

@Injectable({ providedIn: 'root' })
export class UploadService {
  private API_PREFIX = inject(API_PREFIX);
  private keycloakService = inject(KeycloakService);

  createFileUploader(organizationId: string): Observable<FileUploader> {
    return this.getHeadersForFileUploader(this.keycloakService).pipe(
      map(
        headers =>
          new FileUploader({
            url: `${this.API_PREFIX}${RESOURCES.REWRITE.upload(
              organizationId,
            )}`,
            headers,
            autoUpload: true,
            maxFileSize: 20 * 1024 * 1024, // 20MB in bytes,
          }),
      ),
    );
  }

  private getHeadersForFileUploader(
    keycloakService: KeycloakService,
  ): Observable<Headers[]> {
    return this.getContentHeaders(keycloakService).pipe(
      map(requestHeaders =>
        requestHeaders.keys().map((name: string) => {
          const values = requestHeaders?.getAll(name);

          return {
            name,
            value: values[0],
          };
        }),
      ),
    );
  }

  private getContentHeaders(
    keycloakService: KeycloakService,
  ): Observable<HttpHeaders> {
    const headers = new HttpHeaders({
      Accept: 'application/json;charset=utf-8',
    });

    return keycloakService.addTokenToHeader(headers);
  }
}
