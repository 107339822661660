export enum UserView {
  InputTextUpdated = 'UserView/InputTextUpdated',
  SystemPromptUsed = 'UserView/SystemPromptUsed',
  UserPromptUsed = 'UserView/UserPromptUsed',
  ChainUsed = 'UserView/ChainUsed',
  SystemPromptRegenerated = 'UserView/SystemPromptRegenerated',
  UserPromptRegenerated = 'UserView/UserPromptRegenerated',
  ChainRegenerated = 'UserView/ChainRegenerated',
  OutputCopiedToClipboard = 'UserView/OutputCopiedToClipboard',
  FileUploaded = 'UserView/FileUploaded',
  FileDownloaded = 'UserView/FileDownloaded',
  SidebarOpened = 'UserView/SidebarOpened',
  SidebarClosed = 'UserView/SidebarClosed',
  JumpToBottom = 'UserView/JumpToBottom',
  JumpToTop = 'UserView/JumpToTop',
  ResetDialogOpened = 'UserView/ResetDialogOpened',
  Reset = 'UserView/Reset',
  DeleteThreadDialogOpened = 'UserView/DeleteThreadDialogOpened',
  ThreadDeleted = 'UserView/ThreadDeleted',
  FirstCategorySelection = 'UserView/FirstCategorySelection',
  ChangeCategorySelection = 'UserView/ChangeCategorySelection',
  OutputTextUpdatedManually = 'UserView/OutputTextUpdatedManually',
  OutputTextLengthModified = 'UserView/OutputTextLengthModified',
  ThreadCreated = 'UserView/ThreadCreated',
  PreviousThreadOpened = 'UserView/PreviousThreadOpened',
  NextThreadOpened = 'UserView/NextThreadOpened',
  SpecificThreadOpened = 'UserView/SpecificThreadOpened',
}

export enum AdminPanel {
  PromptDeleted = 'AdminPanel/PromptDeleted',
  PromptEditOpened = 'AdminPanel/PromptEditOpened',
  PromptDuplicated = 'AdminPanel/PromptDuplicated',
  PromptsSorted = 'AdminPanel/PromptsSorted',
  CategoryCreated = 'AdminPanel/CategoryCreated',
  CategoryUpdated = 'AdminPanel/CategoryUpdated',
  CategoryDeleted = 'AdminPanel/CategoryDeleted',
  CategoryEditOpened = 'AdminPanel/CategoryEditOpened',
  PromptChainEditOpened = 'AdminPanel/PromptChainEditOpened',
  PromptChainsSorted = 'AdminPanel/PromptChainsSorted',
}

export enum PromptCreation {
  PromptCreationEntered = 'PromptCreation/PromptCreationEntered',
  PromptCreated = 'PromptCreation/PromptCreated',
  PromptMetadataUpdated = 'PromptCreation/PromptMetadataUpdated',
  PromptDeleted = 'PromptCreation/PromptDeleted',
  PromptDuplicated = 'PromptCreation/PromptDuplicated',
}

export enum PromptChainCreation {
  PromptChainCreated = 'PromptChainCreation/PromptChainCreated',
  PromptChainMetadataUpdated = 'PromptChainCreation/PromptChainMetadataUpdated',
  PromptChainActivated = 'PromptChainCreation/PromptChainActivated',
  PromptChainDeactivated = 'PromptChainCreation/PromptChainDeactivated',
  PromptChainReordered = 'PromptChainCreation/PromptChainReordered',
  PromptAddedToChain = 'PromptChainCreation/PromptAddedToTheChain',
  PromptRemovedFromChain = 'PromptChainCreation/PromptRemovedFromChain',
  EditButtonUsed = 'PromptChainCreation/EditButtonUsed',
  PromptChainDeleted = 'PromptChainCreation/PromptChainDeleted',
  PromptChainTestStarted = 'PromptChainCreation/PromptChainTestStarted',
  PromptChainTestFinished = 'PromptChainCreation/PromptChainTestFinished',
}

export enum PromptVersioning {
  VersionTextUpdated = 'PromptVersioning/VersionTextUpdated',
  VersionTextLengthUpdated = 'PromptVersioning/VersionTextLengthUpdated',
  AllowTextLengthModificationChanged = 'PromptVersioning/AllowTextLengthModificationChanged',
  QuotePreservationChanged = 'PromptVersioning/QuotePreservationChanged',
  VersionActivated = 'PromptVersioning/PromptActivated',
  PromptDeactivated = 'PromptVersioning/PromptDeactivated',
  VersionDeleted = 'PromptVersioning/VersionDeleted',
  VersionCreated = 'PromptVersioning/VersionCreated',
  VersionTested = 'PromptVersioning/VersionTested',
}

export enum System {
  OrganizationSwitched = 'System/OrganizationSwitched',
  UserProfileEditOpened = 'System/UserProfileEditOpened',
  NoOrganizationPageVisited = 'System/NoOrganizationPageVisited',
}

export enum Navigation {
  HeaderTabUsed = 'Navigation/HeaderTabUsed',
}

export enum OrganizationProfile {
  MembersTabVisited = 'OrganizationProfile/MembersTabVisited',
  RemoveMemberDialogOpened = 'OrganizationProfile/RemoveMemberDialogOpened',
  MemberRemoved = 'OrganizationProfile/MemberRemoved',
  OrganizationCreated = 'OrganizationProfile/OrganizationCreated',
  InvitationEmailResent = 'OrganizationProfile/InvitationEmailResent',
}

export enum Sessions {
  SessionCreated = 'Sessions/SessionCreated',
  PanelOpened = 'Sessions/PanelOpened',
  SessionLoaded = 'Sessions/SessionLoaded',
  SessionMigrated = 'Sessions/SessionMigrated',
  SessionDeleted = 'Sessions/SessionDeleted',
  DeleteSessionDialogOpened = 'Sessions/DeleteSessionDialogOpened',
  SessionRenamed = 'Sessions/SessionRenamed',
}

export const TrackingEvent = {
  UserView,
  AdminPanel,
  PromptCreation,
  PromptChainCreation,
  PromptVersioning,
  System,
  Navigation,
  OrganizationProfile,
  Sessions,
};

export type TrackingEventType =
  | UserView
  | AdminPanel
  | PromptCreation
  | PromptChainCreation
  | PromptVersioning
  | System
  | Navigation
  | OrganizationProfile
  | Sessions;
