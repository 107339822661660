import { Pipe, PipeTransform } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { PromptChain } from 'rio-models';

@Pipe({
  name: 'promptChainName',
})
export class PromptChainNamePipe implements PipeTransform {
  transform(chain: string, allPromptChains: Dictionary<PromptChain>): string {
    return allPromptChains[chain]?.name;
  }
}
