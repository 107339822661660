import { createEntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { PromptChain } from 'rio-models';
import { PromptChainsState } from './models';
import { promptChainsActions } from './prompt-chains.actions';

export const promptChainsAdapter = createEntityAdapter<PromptChain>();
export const promptChainsInitialState: PromptChainsState =
  promptChainsAdapter.getInitialState();

export const promptChainsReducer = createReducer(
  promptChainsInitialState,

  on(promptChainsActions.loadChainsSuccess, (state, { chains }) =>
    promptChainsAdapter.setAll(chains, state),
  ),

  on(promptChainsActions.deletePromptChainSuccess, (state, { chainId }) =>
    promptChainsAdapter.removeOne(chainId, state),
  ),
);

export const promptChainsFeature = createFeature({
  name: 'promptChains',
  reducer: promptChainsReducer,
});
