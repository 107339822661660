import { createFeatureSelector, createSelector } from '@ngrx/store';
import { categoriesAdapter, categoriesFeature } from './categories.reducer';
import { CategoriesState } from './models';

const categoriesFeatureSelector = createFeatureSelector<CategoriesState>(
  categoriesFeature.name,
);

const { selectAll, selectEntities, selectTotal } =
  categoriesAdapter.getSelectors();

export const selectAllCategoriesAndUncategorized = createSelector(
  categoriesFeatureSelector,
  selectAll,
);

export const selectCategoriesEntities = createSelector(
  categoriesFeatureSelector,
  selectEntities,
);

export const selectCategoriesLength = createSelector(
  categoriesFeatureSelector,
  selectTotal,
);

export const selectAllCategories = createSelector(
  selectAllCategoriesAndUncategorized,
  categoriesAndUncategorized =>
    categoriesAndUncategorized.filter(
      categories => categories.id !== 'uncategorized',
    ),
);
