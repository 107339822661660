import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { routerActions } from '../router/router.actions';
import { userActions } from '../user/user.actions';
import { userFeature } from '../user/user.reducer';
import { organizationsActions } from './organizations.actions';
import { OrganizationsService } from './organizations.service';

@Injectable()
export class OrganizationsEffects {
  changeOrganizationName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.changeOrganizationName),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([{ name }, organizationId]) =>
        this.organizationService
          .changeOrganizationName(organizationId, name)
          .pipe(
            map(({ name }) =>
              organizationsActions.changeOrganizationNameSuccess({
                organizationId,
                name,
              }),
            ),
            catchError(() =>
              of(organizationsActions.changeOrganizationNameFailure()),
            ),
          ),
      ),
    ),
  );

  createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.createOrganization),
      switchMap(({ name, isInternal }) =>
        this.organizationService.createOrganization(name, isInternal).pipe(
          map(({ id }) =>
            organizationsActions.createOrganizationSuccess({
              organizationId: id,
            }),
          ),
          catchError(() =>
            of(organizationsActions.createOrganizationFailure()),
          ),
        ),
      ),
    ),
  );

  createOrganizationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsActions.createOrganizationSuccess),
      switchMap(({ organizationId }) =>
        this.organizationService.getOrganizations().pipe(
          mergeMap(organizations => [
            organizationsActions.loadOrganizationsSuccess({ organizations }),
            userActions.selectOrganization({ organizationId }),
            routerActions.navigateToAPISettings(),
          ]),
          catchError(() => of(organizationsActions.loadOrganizationsFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private organizationService: OrganizationsService,
  ) {}
}
