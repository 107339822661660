import {
  chainIcon,
  ChipIcon,
  defaultPromptIcon,
  noIcon,
  uncategorizedIcon,
} from 'rio-models';

export function getPromptIcon(item: { isDefault?: boolean }): ChipIcon {
  return item?.isDefault ? defaultPromptIcon : noIcon;
}

export function getChainIcon(): ChipIcon {
  return chainIcon;
}

export function getUncategorizedIcon(): ChipIcon {
  return uncategorizedIcon;
}
