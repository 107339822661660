<div
  class="chip fxspacecenter ellipsis"
  [ngClass]="type + ' ' + iconPosition"
  [class.clickable]="clickable"
  [class.large]="large"
  [class.disabled]="disabled"
  [class.truncate]="truncate"
  [style.max-width.px]="maxWidth"
  data-cy="chip"
>
  @if (icon) {
    <mat-icon class="icon">{{ icon }}</mat-icon>
  }

  @if (svgIcon) {
    <mat-icon class="svg-icon" [svgIcon]="svgIcon" />
  }

  <span
    [appDisableTooltip]="text"
    [matTooltip]="text"
    [truncate]="truncate"
    [attr.data-cy]="text + '-chip'"
    >{{ text | translate }}</span
  >
</div>
