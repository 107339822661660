<mat-label class="form-label" [attr.data-cy]="dataCy + '-label'">
  {{ label | translate }}
</mat-label>

<mat-form-field
  appearance="outline"
  class="border white-background radius-6 custom-arrow outline-dropdown panel-below"
  [attr.data-cy]="dataCy + '-dropdown'"
>
  <mat-select
    #select
    multiple
    panelClass="multi-select-panel outline-dropdown-panel scrollable"
    data-cy="select"
    [placeholder]="placeholder | translate"
    [value]="selectedOptions"
    [disabled]="disabled"
    (openedChange)="openDropdown($event)"
  >
    <mat-select-trigger [attr.data-cy]="dataCy + '-placeholder'">
      @if (selectedOptions.includes('all')) {
        {{ 'MultiSelect.Show All' | translate }}
      } @else {
        {{ 'MultiSelect.' + selectedPlaceholder | translate }} ({{
          selectedOptions.length
        }})
      }
    </mat-select-trigger>

    @for (option of availableOptions; track $index) {
      <mat-option
        [value]="option.id"
        (click)="selectOption(option.id)"
        [matTooltip]="option.name"
        [matTooltipDisabled]="wrapper.offsetWidth >= text.scrollWidth"
      >
        <div #wrapper class="fxflex fxcenterbetween full-width">
          <span #text class="black-text ellipsis">{{ option.name }}</span>
          @if (selectedOptions.includes(option.id)) {
            <mat-icon svgIcon="check-simple" />
          }
        </div>
      </mat-option>
    }
  </mat-select>

  <mat-icon
    matSuffix
    class="dropdown-icon"
    svgIcon="chevron-up"
    [class.disabled]="disabled"
  />
</mat-form-field>
