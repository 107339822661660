import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../buttons/button/button.component';
import { IconButtonComponent } from '../buttons/icon-button/icon-button.component';

@Component({
  selector: 'app-tutorial-dialog',
  templateUrl: './tutorial-dialog.component.html',
  styleUrls: ['./tutorial-dialog.component.scss'],
  imports: [ButtonComponent, IconButtonComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutorialDialogComponent {
  @Output() closeDialog = new EventEmitter();

  close() {
    this.closeDialog.emit();
  }
}
