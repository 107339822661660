import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_PREFIX, RESOURCES } from 'rio-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Category,
  CreateCategoryBackendBody,
  CreateCategoryBackendResponse,
  PromptCategoriesResponse,
  PromptCategory,
  PromptCategoryResponse,
  UpdateCategoryBackendBody,
  UpdateCategoryBackendResponse,
} from './models';

@Injectable()
export class CategoriesService {
  API_PREFIX = inject(API_PREFIX);
  httpClient = inject(HttpClient);

  getCategories(organizationId: string): Observable<PromptCategory[]> {
    const url = `${this.API_PREFIX}${RESOURCES.CATEGORIES.categories(
      organizationId,
    )}`;

    return this.httpClient.get<PromptCategoriesResponse>(url).pipe(
      map(response => {
        const categories = response.categories.map(category =>
          this.categoryAdapter(category),
        );

        /* eslint-disable no-prototype-builtins */
        if (response.hasOwnProperty('uncategorized')) {
          const uncategorized: PromptCategory = {
            id: 'uncategorized',
            name: 'Uncategorized',
            isDefault: true,
            prompts: response.uncategorized.prompts ?? [],
            chains: response.uncategorized.chains ?? [],
            svgIcon: 'dots',
          };
          return [...categories, uncategorized];
        }

        return categories;
      }),
    );
  }

  createCategory(
    organizationId: string,
    name: string,
  ): Observable<PromptCategory> {
    const url = `${this.API_PREFIX}${RESOURCES.CATEGORIES.categories(
      organizationId,
    )}`;

    const body: CreateCategoryBackendBody = {
      name,
    };

    return this.httpClient.post<CreateCategoryBackendResponse>(url, body).pipe(
      map(response => ({
        id: response.id,
        name: response.name,
        isDefault: false,
        prompts: [],
        chains: [],
      })),
    );
  }

  editCategory(
    organizationId: string,
    category: Category,
  ): Observable<Category> {
    const url = `${this.API_PREFIX}${RESOURCES.CATEGORIES.category(
      organizationId,
      category.id,
    )}`;

    const body: UpdateCategoryBackendBody = {
      name: category.name,
    };

    return this.httpClient.patch<UpdateCategoryBackendResponse>(url, body);
  }

  deleteCategory(organizationId: string, categoryId: string): Observable<void> {
    const url = `${this.API_PREFIX}${RESOURCES.CATEGORIES.category(
      organizationId,
      categoryId,
    )}`;

    return this.httpClient.delete<void>(url);
  }

  private categoryAdapter(category: PromptCategoryResponse): PromptCategory {
    return {
      id: category.id,
      name: category.name,
      isDefault: category.is_default_category,
      prompts: category.prompts,
      chains: category.chains,
    };
  }
}
