import { Directive, ElementRef, Input, OnChanges, inject } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appDisableTooltip]',
})
export class DisableTooltipDirective implements OnChanges {
  element = inject(ElementRef);
  tooltip = inject(MatTooltip);

  @Input() truncate: boolean;
  @Input() appDisableTooltip: string;

  ngOnChanges() {
    setTimeout(() => {
      const chipText = this.element.nativeElement;
      const displayTooltip =
        this.truncate && chipText.scrollWidth > chipText.offsetWidth;

      this.tooltip.disabled = !displayTooltip;
    });
  }
}
