import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PromptChain } from 'rio-models';

export const promptChainsActions = createActionGroup({
  source: 'PromptChains',
  events: {
    clearAllPromptChains: emptyProps(),

    loadChains: emptyProps(),
    loadChainsSuccess: props<{ chains: PromptChain[] }>(),
    loadChainsFailure: emptyProps(),

    deletePromptChainFromList: props<{ chainId: string }>(),
    openDeletePromptChainDialogFromList: props<{ chainId: string }>(),
    deletePromptChainFromDetails: props<{ chainId: string }>(),
    openDeletePromptChainDialogFromDetails: props<{ chainId: string }>(),
    deletePromptChainSuccess: props<{ chainId: string }>(),
    deletePromptChainFailure: emptyProps(),
  },
});
