<div class="container fxflex fxcolumn fxcentercenter" data-cy="loading-dots">
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
  <div class="text" data-cy="loading-dots-text">
    {{ text | translate: { loaded, total } }}
  </div>
</div>
